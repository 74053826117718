import preset from '~/vehis.preset';

export const useBreakpoints = () => {

  const breakpoint = ref('2xl');
  const screens = preset.theme.extend.screens;


  const onResize = (e) => {
    let calculatedBreakpoint = null;
    Object.keys(screens).forEach(b => {
      if (window.innerWidth >= parseInt(screens[b])) {
        calculatedBreakpoint = b;
      }
    });
    breakpoint.value = calculatedBreakpoint;
  };

  const lte = (breakpointName) => {
    const keys = Object.keys(screens);
    const actual = keys.indexOf(breakpoint.value);
    return actual <= keys.indexOf(breakpointName);
  }

  const gte = (breakpointName) => {
    const keys = Object.keys(screens);
    const actual = keys.indexOf(breakpoint.value);
    return actual >= keys.indexOf(breakpointName);
  }

  const _sm = computed(() => gte('sm'))
  const _md = computed(() => gte('md'))
  const _lg = computed(() => gte('lg'))
  const _xl = computed(() => gte('xl'))
  const _2xl = computed(() => gte('2xl'))

  if (!process.server) {
    window.addEventListener('resize', onResize);
    onResize();
  }

  return {
    breakpoint,
    lte,
    gte,
    _sm,
    _md,
    _lg,
    _xl,
    _2xl
  }
}
